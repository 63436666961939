import React from "react"
import { Container } from "reactstrap" 
import { qualificationType } from "../shared/data-leads";

type HomeProps = {
    formData: qualificationType
    setFormData: (formdata: qualificationType) => any
}
export const Home = (props: HomeProps) => {
    const qualification = props.formData
    console.log("qualification", qualification)
    return (
        <Container>
            <p> { qualification.Name === "" ? "" : `Dear ${qualification.Name}`}, </p>
            <p>Congratulations on taking the first steps to securing the best loan for you. On average our clients save up to $1,000’s of dollars just by getting expert loan advice.</p>
            <p>With access to over 40 lenders, including the majors, we will help you shop the most competitive rates. Unlike the banks, our brokers will find the right product for you and ensure you are always in front of the game and have the best rate and lowest fees. </p>
            <p>As a first home buyer - we can work out exactly which government grants you qualify for simply by completing this quick 2 minute survey. With up to $40,000 in grants and rebates available it's important to have a professional protecting your best interest, getting you into your own home quicker.</p>
            <p>While rates are at an all time low, take action and secure your financial future now!</p>
              
        </Container>
    )
}